import { EventEmitter } from '@angular/core';
import { UserData } from '../auth-service/user-data.model';
import { AppAccessType, HealthScoreResponseModel, ProfileModel } from 'src/app/api/proxy/vitalscore/vitalscore-models';
import { LastFaceScan } from '../face-scan-service/LastFaceScan';
import { SessionConfigResponseModel, StatisticsModel, SummaryModel } from 'src/app/api/proxy/wearables/wearables-models';
import { ILocalAppEnvironment } from '../environment-config-service/environment-config.service';
import { HealthScoreHistory } from '../health-score-service/health-score-history';
import { ProfileScanHistoryModel } from 'src/app/api/proxy/facescan/facescan-models';
import { OrganizationTokenModel } from 'src/app/api/proxy/organization/organization-models';
import { NotificationItemWithArticle } from '../one-signal-service/one-signal.service';

export class EventService {
	static readonly  healthScoreUpdated: EventEmitter<HealthScoreResponseModel> = new EventEmitter<HealthScoreResponseModel>();
	static readonly faceScanUpdated: EventEmitter<LastFaceScan> = new EventEmitter<LastFaceScan>();
	static readonly myProfileUpdated: EventEmitter<ProfileModel> = new EventEmitter<ProfileModel>();
	
	static readonly wearableDialySummaryUpdated: EventEmitter<StatisticsModel> = new EventEmitter<StatisticsModel>();
	static readonly wearableSummaryUpdated: EventEmitter<SummaryModel> = new EventEmitter<SummaryModel>();

	static readonly wearableConfigUpdted: EventEmitter<SessionConfigResponseModel> = new EventEmitter<SessionConfigResponseModel>();
	static readonly appDeeplinkEvent: EventEmitter<string> = new EventEmitter<string>();
	static readonly appPaused: EventEmitter<void> = new EventEmitter<void>();
	static readonly appResumed: EventEmitter<void> = new EventEmitter<void>();
	static readonly pageFocused: EventEmitter<AppAccessType> = new EventEmitter<AppAccessType>();
	static readonly notificationCount: EventEmitter<number> = new EventEmitter<number>();
	static readonly notificationHistoryUpdated: EventEmitter<Array<NotificationItemWithArticle>> = new EventEmitter<Array<NotificationItemWithArticle>>();
	static readonly environmentLoaded: EventEmitter<ILocalAppEnvironment> = new EventEmitter<ILocalAppEnvironment>();
	static readonly userUpdated: EventEmitter<UserData | null> = new EventEmitter<UserData | null>();
	static readonly loadingEvent: EventEmitter<number> = new EventEmitter<number>();
	static readonly healthScoreHistoryUpdated: EventEmitter<HealthScoreHistory> = new EventEmitter<HealthScoreHistory>();
	static readonly faceScanHistoryUpdated: EventEmitter<ProfileScanHistoryModel> = new EventEmitter<ProfileScanHistoryModel>();
	static readonly organizationConfigLoaded: EventEmitter<OrganizationTokenModel> = new EventEmitter<OrganizationTokenModel>();
}
