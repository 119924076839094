import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Observable } from 'rxjs';
import { NavController } from '@ionic/angular';
import { TokenService } from 'src/app/services/token/token.service';
import { UserInfoService } from 'src/app/services/auth-service/user-info-service';
import { EnvironmentConfigService } from 'src/app/services/environment-config-service/environment-config.service';

@Injectable({
	providedIn: 'root'
})
export class LoginGuard {
	constructor(
		private authService: AuthService,
		public navCtrl: NavController,
		private _EnvironmentConfigService: EnvironmentConfigService,
	) {
	}

	canLoad():
		| boolean
		| UrlTree
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree> {
		// Return the result of allowLogin, ensuring it resolves before returning
		return this.allowLogin();
	}

	private async allowLogin(): Promise<boolean> {
		const appConfig = await this._EnvironmentConfigService.EnsureNetworkConfiguration();
		if (!appConfig) {
			return await this.navigateToLogin();
		}

		const userInfo = await UserInfoService.getUserInfo();

		if (!userInfo) {
			return await this.navigateToLogin();
		}

		
		// If we reach this point, the user is logged in
		return true;
	}

	async navigateToLogin() {
		await this.authService.clearUserData(false);
		await this.navCtrl.navigateRoot('/welcome');
		return true; //we are changing the route so we return true
	}

	
}
