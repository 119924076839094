/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppEnvironmentStrictHttpResponse } from '../../app-environment-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CountryItemModel } from '../../models/country-item-model';
import { IpInfoModel } from '../../models/ip-info-model';

export interface AppEnvironmentMyCountryPost$Params {
      body?: IpInfoModel
}

export function appEnvironmentMyCountryPost(http: HttpClient, rootUrl: string, params?: AppEnvironmentMyCountryPost$Params, context?: HttpContext): Observable<AppEnvironmentStrictHttpResponse<CountryItemModel>> {
  const rb = new RequestBuilder(rootUrl, appEnvironmentMyCountryPost.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as AppEnvironmentStrictHttpResponse<CountryItemModel>;
    })
  );
}

appEnvironmentMyCountryPost.PATH = '/AppEnvironment/MyCountry';
