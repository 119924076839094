import { Capacitor } from "@capacitor/core";
import * as Sentry from "@sentry/capacitor";

export class SystemLogService {
  private static logEnabled: boolean = false;
  private initialized = false;

  public isInitialized(): boolean {
    return this.initialized;
  }

  static enableLogging() {
    this.logEnabled = true;
  }

  static logMessage(message: string | null) {
    if (!this.logEnabled) {
      return;
    }
    if (!message)
      return;
    if(Capacitor.getPlatform() == 'ios'){
      Sentry.captureMessage(message, 'debug');
    }
    console.log('WILLIEB->debug->',message);
  }


  static logError(errorObject: unknown, statusText: string = 'error', title: string = 'error') {
    if (!errorObject)
      return;
    if (errorObject instanceof String)
      Sentry.captureMessage(errorObject as string, 'error');
    else
      Sentry.captureException(errorObject);
  }
}


