import { SystemFunctionType } from "src/app/api/proxy/auth/authentication-models";

export const SystemFunctionTypeIndex: { [key in SystemFunctionType]: number } = {
    [SystemFunctionType.PatientAdministration]: 0,
    [SystemFunctionType.OrganizationAdministration]: 1,
    [SystemFunctionType.PractitionerAdministration]: 2,
    [SystemFunctionType.MasterDataAdministration]: 3,
    [SystemFunctionType.SystemUserAdministration]: 4,
    [SystemFunctionType.MediaAdministration]: 5,
    [SystemFunctionType.MediaDashboard]: 6,
    [SystemFunctionType.UserAdministration]: 7,
    [SystemFunctionType.RoleAdministration]: 8,
    [SystemFunctionType.TimesheetUser]: 9,
    [SystemFunctionType.TimesheetAdmin]: 10,
    [SystemFunctionType.MiiAdministrator]: 11,
    [SystemFunctionType.HealthcheckAdministrator]: 12,
    [SystemFunctionType.NotifyAdministrator]: 13,
    [SystemFunctionType.VivascoreGroupDashboard]: 14,
    [SystemFunctionType.SystemDashboardView]: 15,
  };