/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ReportBaseService } from '../report-base-service';
import { ReportApiConfiguration } from '../report-api-configuration';
import { ReportStrictHttpResponse } from '../report-strict-http-response';

import { vitalScoreVitalScoreUsageStatisticsReportPost } from '../fn/vital-score/vital-score-vital-score-usage-statistics-report-post';
import { VitalScoreVitalScoreUsageStatisticsReportPost$Params } from '../fn/vital-score/vital-score-vital-score-usage-statistics-report-post';
import { vitalScoreVitalScoreWeeklyReportPost } from '../fn/vital-score/vital-score-vital-score-weekly-report-post';
import { VitalScoreVitalScoreWeeklyReportPost$Params } from '../fn/vital-score/vital-score-vital-score-weekly-report-post';

@Injectable({ providedIn: 'root' })
export class ReportVitalScoreService extends ReportBaseService {
  constructor(config: ReportApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `vitalScoreVitalScoreWeeklyReportPost()` */
  static readonly VitalScoreVitalScoreWeeklyReportPostPath = '/VitalScore/VitalScoreWeeklyReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vitalScoreVitalScoreWeeklyReportPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  vitalScoreVitalScoreWeeklyReportPost$Response(params?: VitalScoreVitalScoreWeeklyReportPost$Params, context?: HttpContext): Observable<ReportStrictHttpResponse<void>> {
    return vitalScoreVitalScoreWeeklyReportPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vitalScoreVitalScoreWeeklyReportPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  vitalScoreVitalScoreWeeklyReportPost(params?: VitalScoreVitalScoreWeeklyReportPost$Params, context?: HttpContext): Observable<void> {
    return this.vitalScoreVitalScoreWeeklyReportPost$Response(params, context).pipe(
      map((r: ReportStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `vitalScoreVitalScoreUsageStatisticsReportPost()` */
  static readonly VitalScoreVitalScoreUsageStatisticsReportPostPath = '/VitalScore/VitalScoreUsageStatisticsReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vitalScoreVitalScoreUsageStatisticsReportPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  vitalScoreVitalScoreUsageStatisticsReportPost$Response(params?: VitalScoreVitalScoreUsageStatisticsReportPost$Params, context?: HttpContext): Observable<ReportStrictHttpResponse<void>> {
    return vitalScoreVitalScoreUsageStatisticsReportPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vitalScoreVitalScoreUsageStatisticsReportPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  vitalScoreVitalScoreUsageStatisticsReportPost(params?: VitalScoreVitalScoreUsageStatisticsReportPost$Params, context?: HttpContext): Observable<void> {
    return this.vitalScoreVitalScoreUsageStatisticsReportPost$Response(params, context).pipe(
      map((r: ReportStrictHttpResponse<void>): void => r.body)
    );
  }

}
