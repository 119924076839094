/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FaceScanStrictHttpResponse } from '../../face-scan-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ProfileResponseModel } from '../../models/profile-response-model';

export interface SessionValidateScanAllowedSessionIdGet$Params {
  sessionId: string;
}

export function sessionValidateScanAllowedSessionIdGet(http: HttpClient, rootUrl: string, params: SessionValidateScanAllowedSessionIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<ProfileResponseModel>> {
  const rb = new RequestBuilder(rootUrl, sessionValidateScanAllowedSessionIdGet.PATH, 'get');
  if (params) {
    rb.path('sessionId', params.sessionId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as FaceScanStrictHttpResponse<ProfileResponseModel>;
    })
  );
}

sessionValidateScanAllowedSessionIdGet.PATH = '/Session/ValidateScanAllowed/{sessionId}';
