/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ReportStrictHttpResponse } from '../../report-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { HealtCheckResultReportModel } from '../../models/healt-check-result-report-model';

export interface HealthCheckResultsReportPost$Params {
      body?: HealtCheckResultReportModel
}

export function healthCheckResultsReportPost(http: HttpClient, rootUrl: string, params?: HealthCheckResultsReportPost$Params, context?: HttpContext): Observable<ReportStrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, healthCheckResultsReportPost.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as ReportStrictHttpResponse<void>;
    })
  );
}

healthCheckResultsReportPost.PATH = '/HealthCheck/ResultsReport';
