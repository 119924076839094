import { Injectable } from '@angular/core';
import { AuthService } from '../auth-service/auth.service';
import { UserInfoService } from '../auth-service/user-info-service';
import { TeamsService } from '../teams-service/teams.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {

  constructor(
    private authService: AuthService,
    private _teamsService: TeamsService
  ) { 
  }

  public async tokenExpired(): Promise<boolean> {
    const inTeams = await this._teamsService.inTeams();
    if (inTeams) {
      return false;
    }
    return await this.authService.tokenExpired();
  }

  public async getTokenAsync(): Promise<string | null> {
    const inTeams = await this._teamsService.inTeams();
    
    if (inTeams) {
      return await this._teamsService.getToken();
    }

    // Check if the token has expired
    const isTokenExpired = await this.tokenExpired();
    
    // If we have a token that is not expired, return it.
    if (!isTokenExpired) {
      return await this.authService.token();
    }

    // Otherwise, refresh the token
    return await this.refreshTokenAsync();
  }

  public async refreshTokenAsync(): Promise<string | null> {
    // Check if the token is expired before refreshing
    
    const isTokenExpired = await this.tokenExpired();

    if (isTokenExpired == false) {
      return await this.authService.token();      
    }

    const userInfo = await UserInfoService.getUserInfo();
    if (userInfo) {
       await this.authService.refreshToken(false); // Assuming false means no user interaction required
    }

    return await this.authService.token();  
  }

  public async logout(reason:string){
    await this.authService.logout('token service logout ' + reason);
  }

  public async logDebug(mesg: string){
    await this.authService.logDebug(mesg);
  }
}
