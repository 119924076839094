import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, from, Observable, throwError } from 'rxjs';
import { map, catchError, finalize, switchMap, filter, take } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { NavController } from '@ionic/angular';
import { LoadingService } from '../loading-service/loading.service';
import { ILocalAppEnvironment } from '../environment-config-service/environment-config.service';
import { AppEnvironmentAppEnvironmentService } from '../../api/proxy/app-environment/app-environment-services';
import { Capacitor } from '@capacitor/core';
import { App, AppInfo } from '@capacitor/app';
import { AuthenticationLocalAccountService } from 'src/app/api/proxy/auth/authentication-services';
import { OrganizationConsumerService } from 'src/app/api/proxy/organization/organization-services';
import { EventService } from '../events/event.service';
import { UserData } from './user-data.model';
import { TokenService } from '../token/token.service';
import { FaceScanSessionService } from 'src/app/api/proxy/facescan/facescan-services';
import { sharedConfig } from 'src/environments/shared-config';
import { ServicesStartupService } from '../services-startup/services-startup.service';

@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {
  appInfo?: AppInfo | null = null;
  appInfoLoaded = false;
  environment: ILocalAppEnvironment | null = null;
  currentUser: UserData | null = null;

  private isRefreshing = false; // Flag to check if token refresh is happening
  private refreshTokenSubject: Promise<string | null> | null = null; // Holds the refresh token promise
  // BehaviorSubject to track whether all services are initialized

  constructor(
    public navCtrl: NavController,
    private _TokenService: TokenService,
    @Inject(DOCUMENT) private document: Document
  ) {
    EventService.environmentLoaded.subscribe(async (env: ILocalAppEnvironment) => {
      this.environment = env;
    });

    EventService.userUpdated.subscribe(async (usr: UserData | null) => {
      this.currentUser = usr;
    });

  }


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const urls = this.getIgnoredEndpoints();
    for (const url of urls) {
        if (request.url.includes(url)) {
            return next.handle(request);
        }
    }
    
    // Directly handle the request without waiting for services initialization
    return from(this.handle(request, next)).pipe(
        switchMap((result: Observable<HttpEvent<any>>) => result) // Flatten the nested observable
    );
}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async handle(request: HttpRequest<any>, next: HttpHandler): Promise<Observable<HttpEvent<any>>> {
    const ignoreEndpoints = await this.getAnonymousEndpoints();
    if (ignoreEndpoints) {
      for (const endpoint of ignoreEndpoints) {
        if (request.url.includes(endpoint)) {
          return this.handleRequest(request, next);
        }
      }
    }
    const token = await this.refreshToken();
    request = await this.addCustomHeaders(request, token);
    return this.handleRequest(request, next);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Show the loading indicator
    LoadingService.setLoading(true, request.url);


    return next.handle(request).pipe(
      map(evt => {
        // Check if the event is an HttpResponse (successful response)
        if (evt instanceof HttpResponse) {
          // Process successful response if needed
        }
        return evt;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Handle 401 errors (unauthorized) by refreshing the token
          return this.handle401Error(request, next);
        }
        return throwError(() => error);
      }),
      finalize(() => {
        LoadingService.setLoading(false, request.url);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject = this.refreshToken();

      try {
        if (this.refreshTokenSubject) {
          return from(this.refreshTokenSubject).pipe(
            switchMap((newToken) => {
              this.isRefreshing = false;
              this.refreshTokenSubject = null;

              if (newToken) {
                return from(this.addCustomHeaders(request, newToken)).pipe(
                  switchMap(clonedRequest => next.handle(clonedRequest))
                );
              } else {
                return throwError(() => new Error('Token refresh failed'));
              }
            }),
            catchError(error => {
              this.isRefreshing = false;
              this.refreshTokenSubject = null;
              return throwError(() => error);
            })
          );
        } else {
          // Return an observable error if refreshTokenSubject is null
          return throwError(() => new Error('No refresh token subject'));
        }
      } finally {
        this.isRefreshing = false;
      }

    } else {
      return from(this.refreshTokenSubject!).pipe(
        switchMap((newToken) => {
          if (newToken) {
            return from(this.addCustomHeaders(request, newToken)).pipe(
              switchMap(clonedRequest => next.handle(clonedRequest))
            );
          } else {
            return throwError(() => new Error('Token refresh failed during another attempt'));
          }
        }),
        catchError(error => throwError(() => error))
      );
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async addCustomHeaders(request: HttpRequest<any>, token?: string | null): Promise<HttpRequest<any>> {

    if (!token) {
      if (this.currentUser) {
        token = this.currentUser.access_token;
      }
    }
    // Set default headers
    let headers = request.headers
      .set('user-language', this.document.documentElement.lang)
      .set('ngrok-skip-browser-warning', 'true');

    // If the app info is not set, set it
    if (!this.appInfo) {
      this.appInfo = Capacitor.getPlatform() !== 'web' ? await App.getInfo() : {
        name: window.location.hostname,
        id: sharedConfig.webAppId!,
        version: sharedConfig.version,
        build: sharedConfig.build.toString(),
      };
    }

    // Add app info headers if available
    if (this.appInfo && this.environment?.countryCode) {
      headers = headers
        .set('ion-app-deviceType', Capacitor.getPlatform())
        .set('ion-app-name', this.appInfo.name)
        .set('ion-app-id', this.appInfo.id)
        .set('ion-app-version', this.appInfo.version)
        .set('ion-app-build', this.appInfo.build)
        .set('ion-app-countryCode', this.environment.countryCode);
    }

    // Add correlation header if user info is available
    if (this.currentUser) {
      const personVal = btoa(`${this.currentUser.sub!}|${this.currentUser.tokenClaims.personId!}`);
      headers = headers.set('correlation', personVal);
    }

    // Add healthcheck-systemIdentifier-id header if necessary
    if (this.environment && this.environment.faceScanConfig && this.environment.faceScanConfig.faceScanBase
      && request.url.includes(this.environment.faceScanConfig.faceScanBase)) {
      headers = headers.set('healthcheck-systemIdentifier-id', this.environment.faceScanConfig.systemIdentifierId!);
    }

    // Add authorization header if token is provided
    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }

    // Clone the request with the updated headers
    return request.clone({ headers });
  }

  private async refreshToken(): Promise<string | null> {
    if (!this._TokenService) {
      return null;
    }

    try {
      const token = await this._TokenService.refreshTokenAsync();

      return token;
    } catch (error) {
      // Handle token refresh failure (e.g., redirect to login page)

      return null;
    }
  }

  private async getAnonymousEndpoints(): Promise<string[] | null> {
    const result = [
      this.extractUrlWithNoParameters(AuthenticationLocalAccountService.LocalAccountRegisterUserPostPath),
      this.extractUrlWithNoParameters(AuthenticationLocalAccountService.LocalAccountLoginUserPostPath),

      this.extractUrlWithNoParameters(AuthenticationLocalAccountService.LocalAccountRequestOtpPostPath),
      this.extractUrlWithNoParameters(AuthenticationLocalAccountService.LocalAccountVerifyOtpAsyncPostPath),
      this.extractUrlWithNoParameters(AuthenticationLocalAccountService.LocalAccountForgotPasswordRequestPostPath),
      this.extractUrlWithNoParameters(AuthenticationLocalAccountService.LocalAccountResetForgotPasswordPostPath),
      this.extractUrlWithNoParameters(OrganizationConsumerService.ConsumerGetByTokenTokenGetPath),
      this.extractUrlWithNoParameters(AppEnvironmentAppEnvironmentService.AppEnvironmentGetVivaScoreAsyncPostPath),
      this.extractUrlWithNoParameters(AppEnvironmentAppEnvironmentService.AppEnvironmentCountriesGetPath),
      this.extractUrlWithNoParameters(AppEnvironmentAppEnvironmentService.AppEnvironmentSettingsPostPath),


    ];
    return result;
  }

  private getIgnoredEndpoints(): string[] {
    const result: string[] = [
      '/AppLogMessage',
      '/i18n/',
      this.extractUrlWithNoParameters(AppEnvironmentAppEnvironmentService.AppEnvironmentSettingsPostPath),
      this.extractUrlWithNoParameters(AppEnvironmentAppEnvironmentService.AppEnvironmentCountriesGetPath),
      this.extractUrlWithNoParameters(AppEnvironmentAppEnvironmentService.AppEnvironmentEnvironmentSetupEnvironmentNameCountryCodeDeviceTypeNameAppIdVersionBuildProductionGetPath),
      this.extractUrlWithNoParameters(AppEnvironmentAppEnvironmentService.AppEnvironmentCountriesForUserDeviceTypeAppIdProductionGetPath),
      this.extractUrlWithNoParameters(AppEnvironmentAppEnvironmentService.AppEnvironmentVivaScorePingPostPath),


      this.extractUrlWithNoParameters(FaceScanSessionService.SessionRequestSessionPostPath),
      this.extractUrlWithNoParameters(FaceScanSessionService.SessionSessionResultSessionIdGetPath),
      this.extractUrlWithNoParameters(FaceScanSessionService.SessionSdkScanConfigAsyncPostPath),
      this.extractUrlWithNoParameters(FaceScanSessionService.SessionSubmitSdkSessionPostPath),
      this.extractUrlWithNoParameters(FaceScanSessionService.SessionAccessCodeConfigGetSystemIdentifierIdAccessCodeGetPath),

      this.extractUrlWithNoParameters(AuthenticationLocalAccountService.LocalAccountRefreshTokenPostPath),

      'https://api.onesignal.com/'
    ];
    return result;
  }

  extractUrlWithNoParameters(url: string): string {
    const index = url.indexOf('{');
    return index === -1 ? url : url.substring(0, index);
  }


}
