import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/capacitor';
import * as SentryAngular from '@sentry/angular';
import { sharedConfig } from './environments/shared-config';

Sentry.init({
  dsn: "https://850dccc7ec6cff1cd095dfde412c7480@o4508930045313024.ingest.de.sentry.io/4508930076966992",
  debug: !environment.production,
  enabled: environment.production,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: "VivaScore@"+sharedConfig.version,  // Replace with your actual app name and version
    environment: environment.environmentName, // Can be "staging", "development", etc.
    ignoreErrors: ["ResizeObserver loop limit exceeded"],

},
// Forward the init method from @sentry/angular-ivy
SentryAngular.init
);



if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));


  