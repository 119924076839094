enum SecureKeys {
	CurrentRegion = 'CurrentRegion',
	CurrentUser = 'CurrentUser',
	MyProfile = 'MyProfile',
	FaceScanResults = 'FaceScanResults',
	MyHealthScore = 'MyHealthScore',
	MediaCategories = 'MediaCategories',
	LatestArticles = 'LatestArticles',
	WearablesSummary = 'WearablesSummary',
	EnvironmentSettings = 'EnvironmentSettings',
	WearablesGoals = 'WearablesGoals',
	RedirectRoute = 'RedirectRoute',
	DeeplinkPath = 'DeeplinkPath',
	WearableConfig = 'WearableConfig',
	LocalUser = 'LocalUser',
	CurrentScanSessionId = 'CurrentScanSessionId',
	NotificationSummary = 'NotificationSummary',
	BiometricLogin = 'BiometricLogin',
	RegistrationModel = 'RegistrationModel',
	DefualtOrginization ='DefualtOrginization',
	NotificationPermissionCheck = 'NotificationPermissionCheck',
	LastBackfillCheck = 'LastBackfillCheck',
	LastWearableConfigCheck = 'LastWearableConfigCheck',
	LastEnvironmentFetchTime = 'LastEnvironmentFetchTime',
	NextHealthScoreRefresh = 'NextHealthScoreRefresh',
	LastProfileRefresh = 'LastProfileRefresh',
	NextFaceScanRefresh = 'NextFaceScanRefresh',
	HealthScoreHistory = 'HealthScoreHistory',
	FaceScanHistory = 'FaceScanHistory',
	WearablesYesterdaySummary='WearablesYesterdaySummary',
	WearableStatisticsSummary='WearableStatisticsSummary',
	VivaLiteScanResult='VivaLiteScanResult',
	VivaLiteAccessCode='VivaLiteAccessCode',
	VivaLiteProfile='VivaLiteProfile',
	PointsHistory='PointsHistory',
	PKCECodeVerifier='PKCECodeVerifier'


}

export const SecureStorageKey = {  ...SecureKeys };
export type SecureStorageKey = SecureKeys;
