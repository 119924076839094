/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FaceScanBaseService } from '../face-scan-base-service';
import { FaceScanApiConfiguration } from '../face-scan-api-configuration';
import { FaceScanStrictHttpResponse } from '../face-scan-strict-http-response';

import { adminSearchSystemIdentifierAccessCodePost } from '../fn/admin/admin-search-system-identifier-access-code-post';
import { AdminSearchSystemIdentifierAccessCodePost$Params } from '../fn/admin/admin-search-system-identifier-access-code-post';
import { adminSearchSystemIdentifierPost } from '../fn/admin/admin-search-system-identifier-post';
import { AdminSearchSystemIdentifierPost$Params } from '../fn/admin/admin-search-system-identifier-post';
import { adminSystemIdentifierAccessCodeGetIdGet } from '../fn/admin/admin-system-identifier-access-code-get-id-get';
import { AdminSystemIdentifierAccessCodeGetIdGet$Params } from '../fn/admin/admin-system-identifier-access-code-get-id-get';
import { adminSystemIdentifierAccessCodeReportIdGet } from '../fn/admin/admin-system-identifier-access-code-report-id-get';
import { AdminSystemIdentifierAccessCodeReportIdGet$Params } from '../fn/admin/admin-system-identifier-access-code-report-id-get';
import { adminSystemIdentifierAccessSaveIdPost } from '../fn/admin/admin-system-identifier-access-save-id-post';
import { AdminSystemIdentifierAccessSaveIdPost$Params } from '../fn/admin/admin-system-identifier-access-save-id-post';
import { adminSystemIdentifierGet } from '../fn/admin/admin-system-identifier-get';
import { AdminSystemIdentifierGet$Params } from '../fn/admin/admin-system-identifier-get';
import { AdminSystemIdentifierModelSearchResultModel } from '../models/admin-system-identifier-model-search-result-model';
import { adminSystemKeySetupGetIdGet } from '../fn/admin/admin-system-key-setup-get-id-get';
import { AdminSystemKeySetupGetIdGet$Params } from '../fn/admin/admin-system-key-setup-get-id-get';
import { adminSystemKeySetupListGetGet } from '../fn/admin/admin-system-key-setup-list-get-get';
import { AdminSystemKeySetupListGetGet$Params } from '../fn/admin/admin-system-key-setup-list-get-get';
import { adminSystemKeySetupSaveIdPost } from '../fn/admin/admin-system-key-setup-save-id-post';
import { AdminSystemKeySetupSaveIdPost$Params } from '../fn/admin/admin-system-key-setup-save-id-post';
import { FileDownloadModel } from '../models/file-download-model';
import { KeyValueModel } from '../models/key-value-model';
import { SystemIdentifierAccessCodeModel } from '../models/system-identifier-access-code-model';
import { SystemIdentifierAccessCodeModelSearchResultModel } from '../models/system-identifier-access-code-model-search-result-model';
import { SystemKeySetupModel } from '../models/system-key-setup-model';

@Injectable({ providedIn: 'root' })
export class FaceScanAdminService extends FaceScanBaseService {
  constructor(config: FaceScanApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminSearchSystemIdentifierAccessCodePost()` */
  static readonly AdminSearchSystemIdentifierAccessCodePostPath = '/Admin/SearchSystemIdentifierAccessCode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSearchSystemIdentifierAccessCodePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSearchSystemIdentifierAccessCodePost$Response(params?: AdminSearchSystemIdentifierAccessCodePost$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<SystemIdentifierAccessCodeModelSearchResultModel>> {
    return adminSearchSystemIdentifierAccessCodePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSearchSystemIdentifierAccessCodePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSearchSystemIdentifierAccessCodePost(params?: AdminSearchSystemIdentifierAccessCodePost$Params, context?: HttpContext): Observable<SystemIdentifierAccessCodeModelSearchResultModel> {
    return this.adminSearchSystemIdentifierAccessCodePost$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<SystemIdentifierAccessCodeModelSearchResultModel>): SystemIdentifierAccessCodeModelSearchResultModel => r.body)
    );
  }

  /** Path part for operation `adminSystemIdentifierAccessCodeGetIdGet()` */
  static readonly AdminSystemIdentifierAccessCodeGetIdGetPath = '/Admin/SystemIdentifierAccessCodeGet/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSystemIdentifierAccessCodeGetIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSystemIdentifierAccessCodeGetIdGet$Response(params: AdminSystemIdentifierAccessCodeGetIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<SystemIdentifierAccessCodeModel>> {
    return adminSystemIdentifierAccessCodeGetIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSystemIdentifierAccessCodeGetIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSystemIdentifierAccessCodeGetIdGet(params: AdminSystemIdentifierAccessCodeGetIdGet$Params, context?: HttpContext): Observable<SystemIdentifierAccessCodeModel> {
    return this.adminSystemIdentifierAccessCodeGetIdGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<SystemIdentifierAccessCodeModel>): SystemIdentifierAccessCodeModel => r.body)
    );
  }

  /** Path part for operation `adminSystemIdentifierAccessSaveIdPost()` */
  static readonly AdminSystemIdentifierAccessSaveIdPostPath = '/Admin/SystemIdentifierAccessSave/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSystemIdentifierAccessSaveIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSystemIdentifierAccessSaveIdPost$Response(params: AdminSystemIdentifierAccessSaveIdPost$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<string>> {
    return adminSystemIdentifierAccessSaveIdPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSystemIdentifierAccessSaveIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSystemIdentifierAccessSaveIdPost(params: AdminSystemIdentifierAccessSaveIdPost$Params, context?: HttpContext): Observable<string> {
    return this.adminSystemIdentifierAccessSaveIdPost$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `adminSystemIdentifierAccessCodeReportIdGet()` */
  static readonly AdminSystemIdentifierAccessCodeReportIdGetPath = '/Admin/SystemIdentifierAccessCodeReport/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSystemIdentifierAccessCodeReportIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSystemIdentifierAccessCodeReportIdGet$Response(params: AdminSystemIdentifierAccessCodeReportIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<FileDownloadModel>> {
    return adminSystemIdentifierAccessCodeReportIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSystemIdentifierAccessCodeReportIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSystemIdentifierAccessCodeReportIdGet(params: AdminSystemIdentifierAccessCodeReportIdGet$Params, context?: HttpContext): Observable<FileDownloadModel> {
    return this.adminSystemIdentifierAccessCodeReportIdGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<FileDownloadModel>): FileDownloadModel => r.body)
    );
  }

  /** Path part for operation `adminSystemIdentifierGet()` */
  static readonly AdminSystemIdentifierGetPath = '/Admin/SystemIdentifier';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSystemIdentifierGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSystemIdentifierGet$Response(params?: AdminSystemIdentifierGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<Array<KeyValueModel>>> {
    return adminSystemIdentifierGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSystemIdentifierGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSystemIdentifierGet(params?: AdminSystemIdentifierGet$Params, context?: HttpContext): Observable<Array<KeyValueModel>> {
    return this.adminSystemIdentifierGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<Array<KeyValueModel>>): Array<KeyValueModel> => r.body)
    );
  }

  /** Path part for operation `adminSearchSystemIdentifierPost()` */
  static readonly AdminSearchSystemIdentifierPostPath = '/Admin/SearchSystemIdentifier';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSearchSystemIdentifierPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSearchSystemIdentifierPost$Response(params?: AdminSearchSystemIdentifierPost$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<AdminSystemIdentifierModelSearchResultModel>> {
    return adminSearchSystemIdentifierPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSearchSystemIdentifierPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSearchSystemIdentifierPost(params?: AdminSearchSystemIdentifierPost$Params, context?: HttpContext): Observable<AdminSystemIdentifierModelSearchResultModel> {
    return this.adminSearchSystemIdentifierPost$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<AdminSystemIdentifierModelSearchResultModel>): AdminSystemIdentifierModelSearchResultModel => r.body)
    );
  }

  /** Path part for operation `adminSystemKeySetupListGetGet()` */
  static readonly AdminSystemKeySetupListGetGetPath = '/Admin/SystemKeySetupListGet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSystemKeySetupListGetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSystemKeySetupListGetGet$Response(params?: AdminSystemKeySetupListGetGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<Array<SystemKeySetupModel>>> {
    return adminSystemKeySetupListGetGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSystemKeySetupListGetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSystemKeySetupListGetGet(params?: AdminSystemKeySetupListGetGet$Params, context?: HttpContext): Observable<Array<SystemKeySetupModel>> {
    return this.adminSystemKeySetupListGetGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<Array<SystemKeySetupModel>>): Array<SystemKeySetupModel> => r.body)
    );
  }

  /** Path part for operation `adminSystemKeySetupGetIdGet()` */
  static readonly AdminSystemKeySetupGetIdGetPath = '/Admin/SystemKeySetupGet/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSystemKeySetupGetIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSystemKeySetupGetIdGet$Response(params: AdminSystemKeySetupGetIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<SystemKeySetupModel>> {
    return adminSystemKeySetupGetIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSystemKeySetupGetIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSystemKeySetupGetIdGet(params: AdminSystemKeySetupGetIdGet$Params, context?: HttpContext): Observable<SystemKeySetupModel> {
    return this.adminSystemKeySetupGetIdGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<SystemKeySetupModel>): SystemKeySetupModel => r.body)
    );
  }

  /** Path part for operation `adminSystemKeySetupSaveIdPost()` */
  static readonly AdminSystemKeySetupSaveIdPostPath = '/Admin/SystemKeySetupSave/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSystemKeySetupSaveIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSystemKeySetupSaveIdPost$Response(params: AdminSystemKeySetupSaveIdPost$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<string>> {
    return adminSystemKeySetupSaveIdPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSystemKeySetupSaveIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSystemKeySetupSaveIdPost(params: AdminSystemKeySetupSaveIdPost$Params, context?: HttpContext): Observable<string> {
    return this.adminSystemKeySetupSaveIdPost$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<string>): string => r.body)
    );
  }

}
