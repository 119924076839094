/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ReportBaseService } from '../report-base-service';
import { ReportApiConfiguration } from '../report-api-configuration';
import { ReportStrictHttpResponse } from '../report-strict-http-response';

import { biDashboardVivaScoreReportGet } from '../fn/bi-dashboard/bi-dashboard-viva-score-report-get';
import { BiDashboardVivaScoreReportGet$Params } from '../fn/bi-dashboard/bi-dashboard-viva-score-report-get';
import { BiReportTokenModel } from '../models/bi-report-token-model';

@Injectable({ providedIn: 'root' })
export class ReportBiDashboardService extends ReportBaseService {
  constructor(config: ReportApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `biDashboardVivaScoreReportGet()` */
  static readonly BiDashboardVivaScoreReportGetPath = '/BIDashboard/VivaScoreReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `biDashboardVivaScoreReportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  biDashboardVivaScoreReportGet$Response(params?: BiDashboardVivaScoreReportGet$Params, context?: HttpContext): Observable<ReportStrictHttpResponse<BiReportTokenModel>> {
    return biDashboardVivaScoreReportGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `biDashboardVivaScoreReportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  biDashboardVivaScoreReportGet(params?: BiDashboardVivaScoreReportGet$Params, context?: HttpContext): Observable<BiReportTokenModel> {
    return this.biDashboardVivaScoreReportGet$Response(params, context).pipe(
      map((r: ReportStrictHttpResponse<BiReportTokenModel>): BiReportTokenModel => r.body)
    );
  }

}
