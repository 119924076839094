import { SecureStorage } from '@aparajita/capacitor-secure-storage';
import { SecureStorageKey } from './models/secure-storage-key.enum';
import { Preferences } from '@capacitor/preferences';
import { StorageKey } from './models/storage-key.enum';

//this service will convert data from aparajita secure connection to standard capacitor preferences once converted preferences will be used.
//this is done to remove an SSL error that occures in aparajita causing stability issues.
//after all devices moved to the new data storage in a near future version the aparita class can be removed.
export class StorageService {

  private static syncronized = false;

  private static dataConverted = false;

  private static async checkConvertData() {

    if (this.dataConverted)
      return;

    let keys = await SecureStorage.keys();
    if (!keys || keys.length == 0) {
      this.dataConverted = true;
      return;
    }

    if (keys && keys.length) {
      keys.forEach(async (key) => {
        const value = await SecureStorage.get(key, false, StorageService.syncronized)
        if (value) {
          const v = value as string;
          if (v) {
            await this.pref_set(key, v);
          }
        }
        await SecureStorage.remove(key, StorageService.syncronized)
      })
    }
  }

  static async set(key: StorageKey, value: string): Promise<boolean> {

    await this.checkConvertData();
    return await this.pref_set(key,value);
  }

  static async get(key: StorageKey): Promise<string | null> {
    await this.checkConvertData();
    return await this.pref_get(key);

  }

  static async remove(key: StorageKey): Promise<boolean> {
    await this.checkConvertData();
    return await this.pref_remove(key);
  }

  static async clear(): Promise<boolean> {
    await this.checkConvertData();
    return await this.pref_clear();
  }

  static async keys(): Promise<Array<SecureStorageKey>> {
    await this.checkConvertData();
    return await this.pref_keys();
  }


  static async pref_set(key: string, value: string): Promise<boolean> {
    try {
      await Preferences.set({ key, value });
      return true;
    } catch {
      return false;
    }
  }

  static async pref_get(key: string): Promise<string | null> {
    try {
      const { value } = await Preferences.get({ key });
      return value ?? null;
    } catch {
      return null;
    }
  }

  static async pref_remove(key: string): Promise<boolean> {
    try {
      await Preferences.remove({ key });
      return true;
    } catch {
      return false;
    }
  }

  static async pref_clear(): Promise<boolean> {
    try {
      await Preferences.clear();
      return true;
    } catch {
      return false;
    }
  }

  static async pref_keys(): Promise<SecureStorageKey[]> {
    try {
      const { keys } = await Preferences.keys();
      return keys.filter((key) => Object.values(SecureStorageKey).includes(key as SecureStorageKey)) as SecureStorageKey[];
    } catch {
      return [];
    }
  }

}
