import { Injectable } from '@angular/core';
import { SecureStorageService } from '../storage/secure-storage.service';
import { SecureStorageKey } from '../storage/models/secure-storage-key.enum';
import { EventService } from '../events/event.service';
import { NavController } from '@ionic/angular';
import { UserInfoService } from './user-info-service';
import { Semaphore } from 'src/app/utilities/semaphore/semaphore.utility';
import { EnvironmentConfigService } from '../environment-config-service/environment-config.service';
import { AuthenticationLocalAccountService } from 'src/app/api/proxy/auth/authentication-services';
import {
  AccessTokenModel, ChangeEmailAddressRequestModel, ChangePasswordRequestModel, ForgotPasswordRequestModel, LinkSocialAccountRequestModel, LoginRequestModel,
  RefreshTokenRequestModel, RefreshTokenResponseModel, RequestOtpModel, RequestOtpResponseModel, ResetPasswordRequestModel, SocialAccountModel, UnlinkSocialAccountRequestModel, VerifyOtpRequestModel
} from 'src/app/api/proxy/auth/authentication-models';
import { firstValueFrom, timeout } from 'rxjs';
import { differenceInSeconds, subMinutes } from 'date-fns';
import { BiometricLoginDetail, UserData } from './user-data.model';
import { Capacitor } from '@capacitor/core';
import { SystemLogService } from '../systemlog-service/systemlog.service';
import { PromptService } from '../promtp-service/prompt.service';
import { Browser } from '@capacitor/browser';
import { environment } from 'src/environments/environment';
import { TranslatorService } from '../translator-service/translator.service';
import { FaceScanCallbackService } from 'src/app/api/proxy/facescan/facescan-services';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private NETWORK_TIMEOUT = 20000;

  private REFRESH_TIMEOUT = 15000;

  constructor(
    public navCtrl: NavController,
    private _EnvironmentConfigService: EnvironmentConfigService,
    private _localAccountService: AuthenticationLocalAccountService,
    private _PromptService: PromptService
  ) {
    EventService.appPaused.subscribe(async () => {
      this.clearRefresh();
    });
  }


  public async init(): Promise<void> {
    await this.setupRefreshTimer();
  }

  public async tokenExpired(): Promise<boolean> {
    const userInfo = await UserInfoService.getUserInfo();
    if (userInfo && userInfo.tokenExpiry && userInfo.access_token) {
      let dt = new Date(userInfo.tokenExpiry);
      dt = subMinutes(dt, 10); //expire the token 5 minutes in advance for server time skew
      const compareDate = new Date();
      const expired = dt <= compareDate;
      return expired;
    }
    return true; //no user object return true
  }

  public async token(): Promise<string | null> {
    const userInfo = await UserInfoService.getUserInfo();
    if (userInfo && userInfo.access_token) {
      return userInfo.access_token;
    }
    return null;
  }

  public async saveBiometricLogin(countryCode: string, email: string, password: string) {
    if (Capacitor.getPlatform() === 'web') {
      return;
    }
    const biometricLogin: BiometricLoginDetail = {
      username: email,
      password: password,
      countrycode: countryCode
    };
    await SecureStorageService.set(SecureStorageKey.BiometricLogin, JSON.stringify(biometricLogin));
  }

  public async getBiometricLogin(): Promise<BiometricLoginDetail | null> {
    if (Capacitor.getPlatform() === 'web') {
      return null;
    }

    const userData = await SecureStorageService.get(
      SecureStorageKey.BiometricLogin
    );

    if (!userData || userData.length === 0 || userData === '') {
      return null;
    }
    const userInfo = JSON.parse(userData) as BiometricLoginDetail;
    if (!userInfo) {
      return null;
    }

    return userInfo;
  }

  public async clearBiometricLogin(): Promise<void> {
    const bioData = await this.getBiometricLogin();
    if (bioData) {
      await SecureStorageService.set(SecureStorageKey.BiometricLogin, '');
      await SecureStorageService.remove(SecureStorageKey.BiometricLogin);
    }
  }

  public async login(emailAddress: string, pasw: string): Promise<boolean> {
    try {
      const envConfig = await this._EnvironmentConfigService.EnsureNetworkConfiguration();
      if (!envConfig) {
        return false;
      }

      const loginRequest: LoginRequestModel = {
        applicationId: envConfig.authenticateOptions!.appId!,
        applicationScope: envConfig.authenticateOptions!.scope!,
        emailAddress: emailAddress,
        password: pasw,
      };

      let response: AccessTokenModel | null;

      try {
        response = await firstValueFrom(this._localAccountService.localAccountLoginUserPost({ body: loginRequest })
          .pipe(timeout(this.NETWORK_TIMEOUT)));

      } catch (error) {
        await this._PromptService.showNetworkConnectionError(error, 'login');
        return false;
      }

      if (response && response.access_token && response.access_token.length > 0 && !response.error) {
        const tokenResponse = response as AccessTokenModel;
        const token = this.parseJwt(tokenResponse.access_token!);
        // Convert `exp` to milliseconds and create a Date object for expiry
        const tokenExpiry = new Date(token.exp * 1000);
        // Subtract 2 minutes from the expiry time
        const renewTokenTime = new Date(tokenExpiry.getTime() - 2 * 60 * 1000);
        const userInfo: UserData = {
          sub: token.oid,
          given_name: token.given_name,
          family_name: token.family_name,
          name: token.name,
          access_token: tokenResponse.access_token!,
          refresh_token: tokenResponse.refresh_token!,
          tokenExpiry: renewTokenTime.toISOString(),
          tokenClaims: token,
          email: token.userEmailAddress!,
          language: token.language,
          systemFunctions: token.systemFunctions,
          emailVerified: tokenResponse.emailVerified,
          localAccount: token.localAccount,
          socialIssuer: token.idp,
          b2cFlow: token.tfp
        };

        const oldUser = await UserInfoService.getUserInfo();
        if (oldUser && oldUser.sub !== userInfo.sub) {
          await SecureStorageService.clear();
        }
        await UserInfoService.setUserInfo(userInfo);
        await this.setUserLoggedInAsync();
        await this.logDebug('user logged in ' + JSON.stringify(userInfo));
        return true; // Login successful
      }
    } catch (error) {
      console.error('Unexpected error:', error);
    }
    return false;
  }

  public async forgotPasswordRequest(email: string): Promise<boolean> {
    try {
      const model: ForgotPasswordRequestModel = { emailAddress: email };
      await firstValueFrom(this._localAccountService.localAccountForgotPasswordRequestPost({ body: model }).pipe(timeout(this.NETWORK_TIMEOUT)));
      return true;
    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error, 'forgotPasswordRequest');
      return false;
    }
  }

  public async resetForgotPassword(email: string, password: string, otp: number): Promise<boolean> {
    try {
      const model: ResetPasswordRequestModel = { emailAddress: email, newPassword: password, otp: otp };
      await firstValueFrom(this._localAccountService.localAccountResetForgotPasswordPost({ body: model }).pipe(timeout(this.NETWORK_TIMEOUT)));
      await this.clearBiometricLogin();
      return true;
    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error, 'resetForgotPassword');
      return false;
    }
  }

  public async changePassword(password: string) {
    try {
      const model: ChangePasswordRequestModel = { newPassword: password };
      await firstValueFrom(
        this._localAccountService.localAccountChangePasswordPost({ body: model }).pipe(timeout(this.NETWORK_TIMEOUT)));
      if (Capacitor.isNativePlatform()) {
        const bioPassword = await this.getBiometricLogin();
        if (bioPassword) {
          await this.saveBiometricLogin(bioPassword.countrycode, bioPassword.username, password);
        }
      }
      return true;
    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error, 'changePassword');
      return false;
    }
  }

  public async changeEmail(emailaddress: string) {
    try {
      const model: ChangeEmailAddressRequestModel = { newEmail: emailaddress };
      await firstValueFrom(
        this._localAccountService.localAccountChangeEmailPost({ body: model }).pipe(timeout(this.NETWORK_TIMEOUT)));
      if (Capacitor.isNativePlatform()) {
        const bioPassword = await this.getBiometricLogin();
        if (bioPassword) {
          await this.saveBiometricLogin(bioPassword.countrycode, emailaddress, bioPassword.password);
        }
      }
      return true;
    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error, 'change email');
      return false;
    }
  }

  public async registerProfile(tokenResponse: AccessTokenModel): Promise<boolean> {
    try {

      if (tokenResponse && tokenResponse.access_token && tokenResponse.access_token.length > 0 && !tokenResponse.error) {
        const token = this.parseJwt(tokenResponse.access_token);
        // Convert `exp` to milliseconds and create a Date object for expiry
        const tokenExpiry = new Date(token.exp * 1000);
        // Subtract 2 minutes from the expiry time
        const renewTokenTime = new Date(tokenExpiry.getTime() - 2 * 60 * 1000);
        const userInfo: UserData = {
          sub: token.oid,
          given_name: token.given_name,
          family_name: token.family_name,
          name: token.name,
          access_token: tokenResponse.access_token!,
          refresh_token: tokenResponse.refresh_token!,
          tokenExpiry: renewTokenTime.toISOString(),
          tokenClaims: token,
          email: token.userEmailAddress!,
          language: token.language,
          systemFunctions: token.systemFunctions,
          emailVerified: tokenResponse.emailVerified,
          localAccount: token.localAccount,
          socialIssuer: token.idp,
          b2cFlow: token.tfp
        };

        const oldUser = await UserInfoService.getUserInfo();
        if (oldUser && oldUser.sub !== userInfo.sub) {
          await SecureStorageService.clear();
        }
        await UserInfoService.setUserInfo(userInfo);
        await this.setUserLoggedInAsync();
        return true; // Login successful
      } else {
        throw new Error("Unexpected error occurred");
      }
    } catch (error) {
      SystemLogService.logError(error);
      return false;
    }
  }

  public async requestOTP(model: RequestOtpModel): Promise<RequestOtpResponseModel | null> {
    try {
      const response = await firstValueFrom(
        this._localAccountService.localAccountRequestOtpPost({ body: model }).pipe(timeout(this.NETWORK_TIMEOUT)));
      return response;
    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error, 'requestOTP');
      return null;
    }
  }

  public async verifyOTP(model: VerifyOtpRequestModel): Promise<boolean> {
    try {
      const response = await firstValueFrom(
        this._localAccountService.localAccountVerifyOtpAsyncPost({ body: model }).pipe(timeout(this.NETWORK_TIMEOUT)));
      return response == true;
    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error, 'verifyOTP');
      return false;
    }
  }



  private lock = new Semaphore(1);

  public async refreshToken(timerRefresh: boolean): Promise<void> {
    await this.lock.callFunction(async () => {
      await this.logDebug('refresh token inside timer and lock');
      await this.refresh(timerRefresh);
    }, []);
  }

  public async logDebug(mesg: string) {
     const userInfo = await UserInfoService.getUserInfo();
     if (userInfo && userInfo.email && (userInfo.email.toLocaleLowerCase().startsWith('willie'))) {
       try {
         SystemLogService.enableLogging();
         SystemLogService.logMessage('WILLIEB->' + mesg);
       } catch { }
     }
  }

  public async logout(reason?: string): Promise<void> {
    if (reason) {
      await this.logDebug('Logout : ' + reason);
    } else {
      await this.logDebug('Logout : no reason');
    }
    await this.clearUserData(false);

    setTimeout(() => {
      window.location.href = '/welcome'; // Redirects to the root URL
    }, 1000);
  }

  public async clearUserData(clearBiologin: boolean = true): Promise<void> {
    await UserInfoService.setUserInfo(null);
    this.clearRefresh(); //terminate timers 

    const region = await SecureStorageService.get(SecureStorageKey.CurrentRegion);
    const bioLogin = await SecureStorageService.get(SecureStorageKey.BiometricLogin);
    await SecureStorageService.clear();
    if (bioLogin && !clearBiologin) {
      await SecureStorageService.set(SecureStorageKey.BiometricLogin, bioLogin);
    }
    if (region) {
      await SecureStorageService.set(SecureStorageKey.CurrentRegion, region);
      await this._EnvironmentConfigService.EnsureNetworkConfiguration();
    }
  }


  private tokenRefreshRetry = 0;

  private async refresh(timerRefresh: boolean): Promise<void> {
    try {
      const userInfo = await UserInfoService.getUserInfo();
      if (userInfo) {
        const expired = await this.tokenExpired();
        if (!timerRefresh && !expired) {
          return;
        }
        if (!userInfo.refresh_token) {
          await this.logDebug("refresh token - no refresh token available go false");
          await this.logout('User token has no refresh token');
          return;
        }

        let tfp = null;
        if (userInfo && userInfo.tokenClaims && userInfo.tokenClaims.tfp)
          tfp = userInfo.tokenClaims.tfp;

        const env = await this._EnvironmentConfigService.EnsureNetworkConfigurationForToken();
        const refreshOptions: RefreshTokenRequestModel = {
          applicationId: env!.authenticateOptions!.appId!,
          refreshToken: userInfo.refresh_token!,
          tfp: tfp
        }

        let response: RefreshTokenResponseModel | null = null;


        try {

          this.tokenRefreshRetry++;
          await this.logDebug("REFRESH TOKEN request: " + this.tokenRefreshRetry.toString());

          response = await firstValueFrom(
            this._localAccountService.localAccountRefreshTokenPost({ body: refreshOptions })
              .pipe(timeout(this.REFRESH_TIMEOUT))
          );
          this.tokenRefreshRetry = 0;
        } catch (error) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (this.tokenRefreshRetry > 6) {
            let retriesStr = +this.tokenRefreshRetry.toString();
            this.tokenRefreshRetry = 0;
            const err = error as any;
            if (err && err.error && err.error.message) {
              await this._PromptService.showNetworkConnectionError(error, 'refresh');
              await this.logout('REFRESH TOKEN failed ' + err.error.message + ' retries: ' + retriesStr);
              return;

            } else {
              await this._PromptService.showNetworkConnectionError(error, 'refresh ' + ' retries: ' + retriesStr);
              await this.logout('REFRESH TOKEN failed 2');
            }
            response = null;
          } else {
            setTimeout(async () => {
              await this.logDebug("REFRESH TOKEN failed - execture a retry " + this.tokenRefreshRetry.toString());
              await this.refreshToken(timerRefresh);
            });
            return;
          }
        }

        if (!response) {
          await this.setupRefreshTimer();
          return; //try another time
        }

        if (response.error) {
          await this.setupRefreshTimer();
        }

        //we have a bad token - logout the user.
        if (!response.access_token || response.access_token.length <= 0 || response.error) {
          setTimeout(async () => {
            await this.logDebug("REFRESH TOKEN failed - execture a retry returned error3 [" + JSON.stringify(response) + "] " + this.tokenRefreshRetry.toString());
            await this.refreshToken(timerRefresh);
          });
          return;
        }
        const tokenResponse = response as RefreshTokenResponseModel;
        if (tokenResponse && tokenResponse.access_token && tokenResponse.access_token.length > 0 && !tokenResponse.error) {
          const token = this.parseJwt(tokenResponse.access_token);
          // Convert `exp` to milliseconds and create a Date object for expiry
          const tokenExpiry = new Date(token.exp * 1000);
          // Subtract 2 minutes from the expiry time

          const renewTokenTime = new Date(tokenExpiry.getTime() - 2 * 60 * 1000);
          const tokenUser: UserData = {
            sub: token.oid,
            given_name: token.given_name,
            family_name: token.family_name,
            name: token.name,
            access_token: tokenResponse.access_token!,
            refresh_token: tokenResponse.refresh_token!,
            tokenExpiry: renewTokenTime.toISOString(),
            tokenClaims: token,
            email: token.userEmailAddress!,
            language: token.language,
            systemFunctions: token.systemFunctions,
            emailVerified: tokenResponse.emailVerified,
            localAccount: token.localAccount,
            socialIssuer: token.idp,
            b2cFlow: token.tfp
          };


          await UserInfoService.setUserInfo(tokenUser);
          await this.setUserLoggedInAsync();
          await this.logDebug("REFRESH TOKEN  user logged in " + JSON.stringify(tokenUser));
        }

      }
    } catch (error) {
      await this.setupRefreshTimer();
      setTimeout(async () => {
        await this.logDebug("REFRESH TOKEN failed - exception [" + JSON.stringify(error) + "]" + this.tokenRefreshRetry.toString());
        await this.refreshToken(timerRefresh);
      });
    }
  }


  private async setUserLoggedInAsync(): Promise<void> {

    const userInfo = await UserInfoService.getUserInfo();
    if (userInfo) {
      await this.setupRefreshTimer();
    }
  }

  private refreshTimout: any | null = null;
  private clearRefresh() {
    if (this.refreshTimout) {
      clearTimeout(this.refreshTimout);
      this.refreshTimout = null;
    }
  }

  private async singleRefreshSetup() {

    let waitTimeSeconds = 30;
    let userInfo = await UserInfoService.getUserInfo();
    if (!userInfo) {
      return;
    }

    // Get the current time in UTC
    let now = new Date();
    let end = now;

    // Parse the token expiry time as a UTC date
    if (userInfo.tokenExpiry) {
      end = new Date(userInfo.tokenExpiry);
      end = subMinutes(end, 10 - 9); //10 minutes in advance
    }

    // Calculate the difference in seconds between the token expiry and the current time
    let seconds = differenceInSeconds(end, now);

    // Adjust the wait time based on the time remaining before the token expires
    waitTimeSeconds = seconds;
    
    if (waitTimeSeconds <= 5){
      waitTimeSeconds = 0;
    }

    await this.logDebug('Setup refresh timer for '+waitTimeSeconds.toFixed(1)+ ' seconds');
    // Clear any existing refresh interval and set a new one
    this.clearRefresh();
    this.refreshTimout = setTimeout(async () => {
      await this.refreshToken(true);
    }, waitTimeSeconds * 1000);

  }

  private refreshLock = new Semaphore(1); //must be another refresh timee

  private async setupRefreshTimer() {
    const result = false;
    await this.refreshLock.callFunction(async () => {
      await this.logDebug('setupRefreshTimer inside lock');
      await this.singleRefreshSetup();
    }, []);
    return result;

  }

  public async DeleteProfile(): Promise<boolean> {
    try {
      await firstValueFrom(this._localAccountService.localAccountDeleteAccountDelete().pipe(timeout(this.NETWORK_TIMEOUT)));
      //logout and clear local storage
      await this.clearBiometricLogin();
      await this.logout();
      await SecureStorageService.clear();

      return true;
    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error, 'DeleteProfile');
      console.error('Unexpected error:', error);
      return false;
    }
  }

  public async externalSigninOrSignup(providerName: string, isSignup: boolean,
    state: string | undefined = undefined): Promise<boolean> {

    if (!state)
      state = 'login-callback';

    const envConfig = await this._EnvironmentConfigService.EnsureNetworkConfiguration();
    if (!envConfig || !envConfig.authenticateOptions) {
      return false;
    }

    envConfig.authenticateOptions.externalAuthProviders

    const socialProvider = envConfig.authenticateOptions.externalAuthProviders?.find(a => a.providerName === providerName);
    if (!socialProvider)
      return false;

    const codeVerifier = this.generateRandomString(128);
    const codeChallenge = await this.generateCodeChallenge(codeVerifier);

    let flow: string | undefined | null;

    if (isSignup)
      flow = socialProvider.signupFlow;
    else
      flow = socialProvider.loginFlow;

    if (!flow)
      return false;


    let redirectUrl = environment.deepLinkURLBase;
    if (!redirectUrl.endsWith('/'))
      redirectUrl = redirectUrl + '/';
    redirectUrl = redirectUrl + 'login-callback';

    if (Capacitor.getPlatform() == 'ios')
      redirectUrl = environment.iosAuthRedirect;
    else if (Capacitor.getPlatform() == 'android')
      redirectUrl = environment.androidAuthRedirect;

    const scope = [
      'openid',
      'profile',
      'offline_access',
      envConfig.authenticateOptions?.scope
    ].join(' ');

    const pkceRequest: PKCERequest = {
      codeVerifier: codeVerifier,
      flow: flow,
      redirectUrl: redirectUrl,
      scope: envConfig.authenticateOptions.scope!,
      clientId: envConfig.authenticateOptions.appId!,
      baseURL: envConfig.authenticateOptions.b2CBaseURL!
    };

    await SecureStorageService.set(
      SecureStorageKey.PKCECodeVerifier,
      JSON.stringify(pkceRequest)
    );

    const authUrl = `${envConfig.authenticateOptions.b2CBaseURL}oauth2/v2.0/authorize` +
      `?p=${flow}` +
      `&client_id=${envConfig.authenticateOptions?.appId}` +
      `&redirect_uri=${encodeURIComponent(redirectUrl)}` +
      `&response_type=code` +
      `&scope=${encodeURIComponent(scope)}` +
      `&code_challenge=${codeChallenge}` +
      `&code_challenge_method=S256` +
      `&state=${encodeURIComponent(state)}` +
      `&prompt=login`;

    if (Capacitor.isNativePlatform()) {
      await Browser.open({
        url: authUrl
      });
    } else {

      await Browser.open({
        url: authUrl,
        windowName: '_self',
      });
    }
    return true;
  }


  public async processSocialLoginCode(code: string | null | undefined,
    error: string | null | undefined,
    errorDescription: string | null | undefined,
    state: string | null | undefined,
    translator: TranslatorService): Promise<ExternalSignUpCallbackResult> {


    let result: ExternalSignUpCallbackResult = {
      success: false,
      errorMessage: translator.translateText('account-service.errors.generic-error'),
      redirectURL: 'home'
    };

    if (state) {
      if (state.startsWith('LINKACCOUNT')) {
        result.redirectURL = 'home/link-account';
      }
      if (state.startsWith('SIGNUP-CONSUMER')) {
        result.redirectURL = 'v2/registration/get-vivascore';
      }
    }



    if (error) {
      const decodedError = decodeURIComponent(errorDescription || '');
      let errorMessage = decodedError;
      if (decodedError.includes('AADB2C99001')) {
        errorMessage = translator.translateText('account-service.errors.account-already-inuse');
      }

      if (decodedError.includes('AADB2C90075')) {
        errorMessage = translator.translateText('account-service.errors.social-provider-failed');
      }

      if (decodedError.includes('AADB2C99002')) {
        errorMessage = translator.translateText('account-service.errors.user-does-not-exist');
      }

      result.errorMessage = errorMessage;
      result.success = false;
     


      return result;
    }

    if (code) {
      const storedValue = await SecureStorageService.get(SecureStorageKey.PKCECodeVerifier);
      if (!storedValue) {
        return result;
      }

      const requestType: PKCERequest = JSON.parse(storedValue) as PKCERequest;
      const body = new URLSearchParams({
        grant_type: 'authorization_code',
        client_id: requestType.clientId,
        redirect_uri: requestType.redirectUrl,
        code: code,
        code_verifier: requestType.codeVerifier!,
        scope: 'openid profile offline_access ' + requestType.scope
      });

      try {
        const postResponse = await fetch(`${requestType.baseURL}oauth2/v2.0/token?p=${requestType.flow}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: body.toString()
        });

        const tokenResult: PKETokenResult = await postResponse.json();

        if (tokenResult && tokenResult.access_token && tokenResult.access_token.length > 0) {
          const token = this.parseJwt(tokenResult.access_token);
          // Convert `exp` to milliseconds and create a Date object for expiry
          const tokenExpiry = new Date(token.exp * 1000);
          // Subtract 2 minutes from the expiry time
          const renewTokenTime = new Date(tokenExpiry.getTime() - 2 * 60 * 1000);
          const userInfo: UserData = {
            sub: token.oid,
            given_name: token.given_name,
            family_name: token.family_name,
            name: token.name,
            access_token: tokenResult.access_token!,
            refresh_token: tokenResult.refresh_token!,
            tokenExpiry: renewTokenTime.toISOString(),
            tokenClaims: token,
            email: token.userEmailAddress!,
            language: token.language,
            systemFunctions: token.systemFunctions,
            emailVerified: true,
            localAccount: token.localAccount,
            socialIssuer: token.idp,
            b2cFlow: token.tfp
          };


          if (state) {

            if (state.startsWith('LINKACCOUNT')) {
              const requestId = state.split('|')[1];
              if (userInfo.socialIssuer && requestId) {
                await this.linkSocialAccount(requestId, userInfo.socialIssuer, userInfo.sub);
                result.success = true;
                result.redirectURL = 'home/link-account';
                return result;
              }
            }
          }


          const oldUser = await UserInfoService.getUserInfo();
          if (oldUser && oldUser.sub !== userInfo.sub) {
            await SecureStorageService.clear();
          }
          await UserInfoService.setUserInfo(userInfo);
          await this.setUserLoggedInAsync();

          //investigate state variable to see what to do
          if (state) {
            if (state.startsWith('SIGNUP-CONSUMER')) {
              result.success = true;
              result.redirectURL = 'v2/registration/confirm-profile';
              return result;
            }
          }
          result.success = true;
          result.redirectURL = 'home';
        }
      } catch (error) {
        console.error('Token exchange failed:', error);
        result.success = false;
      }
    }
    return result;
  }

  public async getLinkedSocialAccounts(): Promise<SocialAccountModel[] | null> {
    try {
      const envConfig = await this._EnvironmentConfigService.EnsureNetworkConfiguration();
      if (!envConfig) {
        return null;
      }


      const result = await firstValueFrom(
        this._localAccountService.localAccountLinkedSocialAccountGet().pipe(timeout(this.NETWORK_TIMEOUT))
      );
      return result;
    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error, 'getLinkedSocialAccounts');
      return null;
    }
  }

  public async requestAccountLink(providerName: string): Promise<boolean> {
    try {
      const envConfig = await this._EnvironmentConfigService.EnsureNetworkConfiguration();
      if (!envConfig) {
        return false;
      }

      const linkRequestId = await firstValueFrom(
        this._localAccountService.localAccountRequestAccountLinkPost().pipe(timeout(this.NETWORK_TIMEOUT))
      );

      if (typeof linkRequestId === 'string') {
        return await this.externalSigninOrSignup(providerName, true, 'LINKACCOUNT|' + (linkRequestId as string));
      }

      return false;
    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error, 'requestAccountLink');
      return false;
    }
  }

  public async linkSocialAccount(linkRequestId: string, issuer: string, sourceUserB2CId: string): Promise<SocialAccountModel[] | null> {
    const envConfig = await this._EnvironmentConfigService.EnsureNetworkConfiguration();
    if (!envConfig) {
      return null;
    }
    const userInfo = await UserInfoService.getUserInfo();
    if (!userInfo) {
      return null;
    }

    try {
      const model: LinkSocialAccountRequestModel = {
        linkToken: linkRequestId,
        issuer: issuer,
        sourceUserB2CId: sourceUserB2CId
      }

      const result = await firstValueFrom(
        this._localAccountService.localAccountLinkAccountPost({ body: model }).pipe(timeout(this.NETWORK_TIMEOUT))
      );
      return result;
    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error, 'localAccountLinkAccountPost');
      return null;
    }
  }

  public async unlinkSocialAccount(account: SocialAccountModel): Promise<SocialAccountModel[] | null> {
    const envConfig = await this._EnvironmentConfigService.EnsureNetworkConfiguration();
    if (!envConfig) {
      return null;
    }

    const userInfo = await UserInfoService.getUserInfo();
    if (!userInfo) {
      return null;
    }

    try {
      const model: UnlinkSocialAccountRequestModel = {
        issuer: account.issuer!,
      }

      const result = await firstValueFrom(
        this._localAccountService.localAccountUnlinkAccountPost({ body: model }).pipe(timeout(this.NETWORK_TIMEOUT))
      );

      return result;
    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error, 'unlinkSocialAccount');
      return null;
    }
  }

  private generateRandomString(length: number): string {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    let result = '';
    const values = new Uint32Array(length);
    window.crypto.getRandomValues(values);
    for (let i = 0; i < length; i++) {
      result += charset[values[i] % charset.length];
    }
    return result;
  }

  private async generateCodeChallenge(codeVerifier: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await window.crypto.subtle.digest('SHA-256', data);
    return btoa(String.fromCharCode(...new Uint8Array(digest)))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }


  private parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    let payload = JSON.parse(jsonPayload);

    if (!payload.personId) {
      payload.personId = payload.extension_PersonId;
    }

    if (!payload.organizationId)
      payload.organizationId = payload.extension_OrganizationId;

    if (!payload.language)
      payload.language = payload.extension_Language;

    if (!payload.userEmailAddress)
      payload.userEmailAddress = payload.extension_UserEmailAddress;

    if (!payload.systemFunctions && payload.extension_SystemFunctions)
      payload.systemFunctions = payload.extension_SystemFunctions.split("|");

    if (!payload.idp)
      payload.localAccount = true;
    else
      payload.localAccount = false;

    return payload;
  }

}


export interface ExternalSignUpCallbackResult {
  success: boolean,
  redirectURL: string,
  errorMessage: string
}
interface PKCERequest {
  codeVerifier: string,
  flow: string,
  redirectUrl: string,
  scope: string,
  clientId: string,
  baseURL: string
}

interface PKETokenResult {
  access_token: string,
  id_token: string,
  token_type: string,
  expires_in: number,
  expires_on: number,
  resource: string,
  id_token_expires_in: number,
  profile_info: string,
  scope: string,
  refresh_token: string,
  refresh_token_expires_in: number
}


