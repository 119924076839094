/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ReportBaseService } from '../report-base-service';
import { ReportApiConfiguration } from '../report-api-configuration';
import { ReportStrictHttpResponse } from '../report-strict-http-response';

import { healthCheckResultsReportPost } from '../fn/health-check/health-check-results-report-post';
import { HealthCheckResultsReportPost$Params } from '../fn/health-check/health-check-results-report-post';

@Injectable({ providedIn: 'root' })
export class ReportHealthCheckService extends ReportBaseService {
  constructor(config: ReportApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `healthCheckResultsReportPost()` */
  static readonly HealthCheckResultsReportPostPath = '/HealthCheck/ResultsReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthCheckResultsReportPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthCheckResultsReportPost$Response(params?: HealthCheckResultsReportPost$Params, context?: HttpContext): Observable<ReportStrictHttpResponse<void>> {
    return healthCheckResultsReportPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `healthCheckResultsReportPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthCheckResultsReportPost(params?: HealthCheckResultsReportPost$Params, context?: HttpContext): Observable<void> {
    return this.healthCheckResultsReportPost$Response(params, context).pipe(
      map((r: ReportStrictHttpResponse<void>): void => r.body)
    );
  }

}
