/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReportApiConfiguration, ReportApiConfigurationParams } from './report-api-configuration';

import { ReportBiDashboardService } from './services/report-bi-dashboard.service';
import { ReportHealthCheckService } from './services/report-health-check.service';
import { ReportMonitorService } from './services/report-monitor.service';
import { ReportVitalScoreService } from './services/report-vital-score.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ReportBiDashboardService,
    ReportHealthCheckService,
    ReportMonitorService,
    ReportVitalScoreService,
    ReportApiConfiguration
  ],
})
export class ReportModule {
  static forRoot(params: ReportApiConfigurationParams): ModuleWithProviders<ReportModule> {
    return {
      ngModule: ReportModule,
      providers: [
        {
          provide: ReportApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ReportModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ReportModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
