import { EventService } from '../events/event.service';


export class LoadingService {
  private static loadingMap: Map<string, boolean> = new Map<string, boolean>();
  private static expiryDate: Date | null = null;
  private static loadingDisabled:boolean = false;

  private static interval: any;
  static init(){
    if(LoadingService.interval)
      clearInterval(LoadingService.interval);
    LoadingService.interval = setInterval(() => { LoadingService.checkClearLoading() }, 1000);

  }

  private static checkClearLoading() {
    const now = new Date();
    if (this.expiryDate && now > this.expiryDate) {
      this.closeAllLoaders();
    }
  }

  static disableLoading(){
    this.loadingDisabled = true;
    this.closeAllLoaders();
  }

  static enableLoading(){
    this.loadingDisabled = false;
    this.closeAllLoaders();
  }



  static setLoading(loading: boolean, url: string) {
    if (loading) {
      if(this.loadingDisabled)
        return;

      this.loadingMap.set(url, loading);

      // Set the expiry date to 30 seconds from now
      this.expiryDate = new Date();
      this.expiryDate.setTime(this.expiryDate.getTime() + 30 * 1000); // Add 30 seconds

    } else {
      if (this.loadingMap.has(url)) {
        this.loadingMap.delete(url);
      }
      if (this.loadingMap.size <= 0)
        this.expiryDate = null;
    }
    if (!loading) {
      setTimeout(() => {
        EventService.loadingEvent.emit(this.loadingMap.size);
      }, 500);//debounce the event so that we kill the flashing loaders
    } else {
      EventService.loadingEvent.emit(this.loadingMap.size);
    }
  }


  static closeAllLoaders() {
    this.loadingMap.clear();
    EventService.loadingEvent.emit(this.loadingMap.size);
  }

  static getLastUrlSegments(url: string, segmentCount: number): string {
    const segments = url.split('/').filter(segment => segment.length > 0);
    const lastSegments = segments.slice(-segmentCount);
    return lastSegments.join('/');
  }

  static getLastUrlSegment(url: string) {
    if (!url) return url;
    if (!url.includes('/')) return url;
    try {
      let lastSegment = new URL(url).pathname.split('/').filter(Boolean).pop();
      if (!lastSegment) lastSegment = '';

      if (this.isUUID(lastSegment)) {
        const arr = new URL(url).pathname.split('/').filter(Boolean);
        if (arr.length > 1) return arr[arr.length - 2];
      }
      return lastSegment;
    } catch {
      return url;
    }
  }

  static isUUID(uuid: string) {
    const s = "" + uuid;
    const matchArrayy = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    if (matchArrayy === null) {
      return false;
    }
    return true;
  }

  public static removeParametersFromUrl(url: string): string {
    const paramRegex = /{[^{}]+}/g;
    return url.replace(paramRegex, '');
  }


}
