import { Injectable } from '@angular/core';
import { SecureStorageKey } from './models/secure-storage-key.enum';
import { StorageService } from './storage.service';


export class SecureStorageService {

	static async set(key: SecureStorageKey, value: string): Promise<boolean> {
		return await StorageService.set(key, value);
	}

	static async get(key: SecureStorageKey): Promise<string | null> {
		return await StorageService.get(key);
	}

	static async remove(key: SecureStorageKey): Promise<boolean> {
		return await StorageService.remove(key);
	}


	static async clear(): Promise<boolean> {
		return await StorageService.clear();
	}

	static async keys(): Promise<Array<SecureStorageKey>> {
		return await StorageService.keys();
	}


}
