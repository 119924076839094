/* tslint:disable */
/* eslint-disable */
export enum SystemFunctionType {
  PatientAdministration = 'PATIENT_ADMINISTRATION',
  OrganizationAdministration = 'ORGANIZATION_ADMINISTRATION',
  PractitionerAdministration = 'PRACTITIONER_ADMINISTRATION',
  MasterDataAdministration = 'MASTER_DATA_ADMINISTRATION',
  SystemUserAdministration = 'SYSTEM_USER_ADMINISTRATION',
  MediaAdministration = 'MEDIA_ADMINISTRATION',
  MediaDashboard = 'MEDIA_DASHBOARD',
  UserAdministration = 'USER_ADMINISTRATION',
  RoleAdministration = 'ROLE_ADMINISTRATION',
  TimesheetUser = 'TIMESHEET_USER',
  TimesheetAdmin = 'TIMESHEET_ADMIN',
  MiiAdministrator = 'MII_ADMINISTRATOR',
  HealthcheckAdministrator = 'HEALTHCHECK_ADMINISTRATOR',
  NotifyAdministrator = 'NOTIFY_ADMINISTRATOR',
  VivascoreGroupDashboard = 'VIVASCORE_GROUP_DASHBOARD',
  SystemDashboardView = 'SYSTEM_DASHBOARD_VIEW'
}
